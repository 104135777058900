import { api } from "../../../services/api";

import { getToken } from "../../../oauth/fx/token";

export const getUser = async () => {
  try {
    const token = getToken();

    if (token) {
      let response = await api.get("/user-info", {
        headers: {
          authorization: `Authorization ${token}`,
        },
      });

      const data = response.data;

      return data;
    } else {
      return null;
    }
  } catch (error) {
    return error;
  }
};

export const QtdCandidate = async () => {
  try {
    let response = await api.get("/qtd-candidato/colecao");

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const QtdEmployer = async () => {
  try {
    let response = await api.get("/qtd-contratante/colecao");

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const QtdJobVacancy = async () => {
  try {
    let response = await api.get("/qtd-vaga/colecao");

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const QtdExternalJobVacancy = async () => {
  try {
    let response = await api.get("/qtd-vaga-externa/colecao");

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};
