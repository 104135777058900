/* React */
import React, { useState } from "react";

/* Material ui */
import {
  Box,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
} from "@mui/material";
import { useTheme as MuiUseTheme } from "@mui/material";

import { CardTitle } from "../../../../../components/shared/Card/Main";

/* Contexts */
import { useTheme } from "../../../../../contexts/ThemeProvider/useTheme";

export const Appearance = () => {
  const theme = MuiUseTheme();
  const contextTheme = useTheme();

  const [mode, setMode] = useState(theme.palette.mode);

  const handleModeChange = (event) => {
    setMode(event.target.value);
    contextTheme.handleTheme(event.target.value);
  };

  return (
    <Box my={3}>
      <CardTitle
        title="Customização de Aparência"
        description="Esta configuração será aplicada apenas neste navegador."
      >
        <Box my={2}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              <Typography component="div" variant="span" fontSize={14} mb={2}>
                Configuração de temas
              </Typography>
            </FormLabel>
            <RadioGroup
              mt={5}
              aria-label="mode"
              value={mode}
              onChange={handleModeChange}
            >
              <FormControlLabel
                value="dark"
                control={<Radio />}
                label="Noturno"
              />
              <FormControlLabel
                value="light"
                control={<Radio />}
                label="Claro"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </CardTitle>
    </Box>
  );
};
