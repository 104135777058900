/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Material */
import { Grid, Box, Button, Divider } from "@mui/material";

/* Icons */
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

/* Components */
import FormControl from "../../../../../../components/app/Form/FormControl";
import useForm from "../../../../../../components/app/Form/FormControl/useForm";
import { Controls } from "../../../../../../components/app/Form/Controls";
import { SubTitlePage } from "../../../../../../components/app/Title";
import { Alert } from "../../../../../../components/app/Alert";

/* Functions */
import { CreateJob, ListEmployer } from "../../../util";

/* Styles */
import { Drawer, DrawerHeader } from "../../styled/Drawer";

const initialValues = {
  titulo: "",
  modalidade: "Presencial",
  tituloContratante: "",
  cep: "",
  uf: "DF",
  descricao: "",
  linkExterno: "",
  idContratante: "",
  confidencial: false,
};

export const DrawerAdd = (props) => {
  // props
  const { open, setOpen } = props;

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const [employer, setEmployer] = useState([]);

  const isEmpty = (value) => {
    const result = value ? "" : "Preencha este campo.";
    return result;
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("titulo" in fieldValues) {
      temp.titulo = isEmpty(fieldValues.titulo);
    }

    if ("descricao" in fieldValues) {
      temp.descricao = isEmpty(fieldValues.descricao);
    }

    if ("modalidade" in fieldValues) {
      temp.modalidade = isEmpty(fieldValues.modalidade);
    }

    if ("tituloContratante" in fieldValues) {
      temp.tituloContratante = isEmpty(fieldValues.tituloContratante);
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    // setValues,
    errors,
    setErrors,
    handleInputChange,
    handleInputBlur,
  } = useForm(initialValues, true, validate);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setMessageError("");
    setOpenError(false);

    setTimeout(async () => {
      try {
        if (validate()) {
          await CreateJob(
            values.titulo,
            values.modalidade,
            values.tituloContratante,
            values.cep,
            values.uf,
            values.descricao,
            values.linkExterno,
            values.idContratante,
            values.confidencial
          )
            .catch((error) => {
              console.log(error);
              setMessageError("Ocorreu um erro ao salvar.");
              setOpenError(true);
            })
            .finally(() => {
              goList();
            });
        } else {
          setMessageError("Por favor, corrija os campos em vermelho.");
          setOpenError(true);
        }
      } catch (error) {
        // internal error
        setMessageError("Ocorreu um erro inesperado na sessão.");
        setOpenError(true);
      }
    }, 100);
  };

  const closeDrawer = () => {
    setOpen(!open);
  };

  const goList = () => {
    window.location.reload();
  };

  const load = useCallback(async () => {
    let unmounted = false;

    try {
      setTimeout(async () => {
        await ListEmployer().then((response) => {
          const { result } = response;

          if (!unmounted && result.length > 0) {
            let data = [];

            result.filter(function (item) {
              let obj = {
                title: item.nome,
                value: item.id,
              };

              return data.push(obj);
            });

            setEmployer(data);
          }
        });
      }, 500);
    } catch (error) {
      console.log(error);

      if (!unmounted) {
        setMessageError(error.message);
        setOpenError(true);
      }
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load]);

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open} onClose={closeDrawer}>
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            px={2}
          >
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={closeDrawer}
            >
              Voltar
            </Button>
          </Box>
        </DrawerHeader>

        <Divider
          sx={{
            borderColor: (theme) => theme.palette.background.default,
            marginBottom: (theme) => theme.spacing(1.5),
          }}
        />

        <Box px={4} py={2}>
          <Box mb={4}>
            <SubTitlePage>Nova vaga</SubTitlePage>

            <FormControl onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controls.Input
                    label="Título da área"
                    name="titulo"
                    value={values.titulo}
                    error={errors.titulo}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.TextArea
                    label="Descrição"
                    name="descricao"
                    value={values.descricao}
                    error={errors.descricao}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Select
                    label="Modalidade"
                    name="modalidade"
                    nullValue={false}
                    items={[
                      { title: "Remoto", value: "Remoto" },
                      { title: "Presencial", value: "Presencial" },
                    ]}
                    value={values.modalidade}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Checkbox
                    name="confidencial"
                    label="Vaga confidencial (os candidatos não visualizarão o nome da Instituição)"
                    value={values.confidencial}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    label="Título do contratante"
                    name="tituloContratante"
                    value={values.tituloContratante}
                    error={errors.tituloContratante}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    label="CEP"
                    name="cep"
                    value={values.cep}
                    error={errors.cep}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Select
                    label="UF"
                    name="uf"
                    nullValue={false}
                    items={[
                      { title: "Acre (AC)", value: "AC" },
                      { title: "Alagoas (AL)", value: "AL" },
                      { title: "Amapá (AP)", value: "AP" },
                      { title: "Amazonas (AM)", value: "AM" },
                      { title: "Bahia (BA)", value: "BA" },
                      { title: "Ceará (CE)", value: "CE" },
                      { title: "Distrito Federal (DF)", value: "DF" },
                      { title: "Espírito Santo (ES)", value: "ES" },
                      { title: "Goiás (GO)", value: "GO" },
                      { title: "Maranhão (MA)", value: "MA" },
                      { title: "Mato Grosso (MT)", value: "MT" },
                      { title: "Mato Grosso do Sul (MS)", value: "MS" },
                      { title: "Minas Gerais (MG)", value: "MG" },
                      { title: "Pará (PA) ", value: "PA" },
                      { title: "Paraíba (PB)", value: "PB" },
                      { title: "Paraná (PR)", value: "PR" },
                      { title: "Pernambuco (PE)", value: "PE" },
                      { title: "Piauí (PI)", value: "PI" },
                      { title: "Rio de Janeiro (RJ)", value: "RJ" },
                      { title: "Rio Grande do Norte (RN)", value: "RN" },
                      { title: "Rio Grande do Sul (RS)", value: "RS" },
                      { title: "Rondônia (RO)", value: "RO" },
                      { title: "Roraima (RR)", value: "RR" },
                      { title: "Santa Catarina (SC)", value: "SC" },
                      { title: "São Paulo (SP)", value: "SP" },
                      { title: "Sergipe (SE)", value: "SE" },
                      { title: "Tocantins (TO)", value: "TO" },
                    ]}
                    value={values.uf}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controls.Input
                    label="Link externo"
                    name="linkExterno"
                    value={values.linkExterno}
                    error={errors.linkExterno}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                </Grid>

                {employer && employer.length > 0 ? (
                  <Grid item xs={12}>
                    <Controls.Select
                      label="Contratante"
                      name="idContratante"
                      nullValue={true}
                      items={employer}
                      value={values.idContratante}
                      onChange={handleInputChange}
                    />
                  </Grid>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                <Grid item xs={12}>
                  {openError ? (
                    <Box mb={2}>
                      <Alert message={messageError} open={openError} />
                    </Box>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}

                  <Controls.Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    width="100%"
                    text="Salvar"
                    endIcon={<ArrowForwardIcon />}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};
