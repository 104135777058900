/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Material */
import {
  Box,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  FormGroup,
  FormControlLabel,
  Switch,
  Divider,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

/* Icons */
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

/* Components */
import { Alert } from "../../../../../../components/app/Alert";
import { SubTitlePage } from "../../../../../../components/app/Title";

/* Functions */
import { Show, StatusAccount, DeleteAccount } from "../../../util";

/* Styles */
import { Drawer, DrawerHeader } from "../../styled/Drawer";
import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../../styled/Table";

export const DrawerUser = (props) => {
  // props
  const { open, setOpen, id, refreshList } = props;

  // states
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [user, setUser] = useState(null);

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const handleClickCloseUDialog = () => {
    setUserId("");
    setUserName("");
    setOpenDialog(false);
  };

  const handleClickOpenUDialog = (id, name) => {
    setUserId(id);
    setUserName(name);
    setOpenDialog(true);
  };

  const handleChangeStatus = async (event, id) => {
    const checked = event.target.checked;

    await StatusAccount(checked, id).catch((error) => {
      console.log(error);
      setMessageError(error.message);
      setOpenError(true);
    });
  };

  const handleChangeDelete = async () => {
    await DeleteAccount(userId)
      .catch((error) => {
        console.log(error);
        setMessageError(error.message);
        setOpenError(true);
      })
      .finally(() => {
        goList();
      });
  };

  const closeDrawer = () => {
    setOpen(!open);
    refreshList();
  };

  const goList = () => {
    window.location.reload();
  };

  const load = useCallback(async () => {
    let unmounted = false;

    try {
      setUser(null);
      setOpen(open);

      setTimeout(async () => {
        if (id) {
          await Show(id)
            .then((response) => {
              const { result } = response;

              if (!unmounted) {
                setUser(result);
              }
            })
            .catch((error) => {
              console.log(error);
              setMessageError(error.message);
              setOpenError(true);
            });
        }
      }, 500);
    } catch (error) {
      console.log(error);
      setMessageError(error.message);
      setOpenError(true);
    }

    return () => {
      unmounted = true;
    };
  }, [open, setOpen, id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load, open, setOpen, id]);

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open} onClose={closeDrawer}>
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            px={2}
          >
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={closeDrawer}
            >
              Voltar
            </Button>
          </Box>
        </DrawerHeader>

        <Divider
          sx={{
            borderColor: (theme) => theme.palette.background.default,
          }}
        />

        {user ? (
          <Box
            sx={{
              color: "#ffffff",
              textAlign: "center",
              backgroundColor: (theme) =>
                `${
                  user.ativo
                    ? `${theme.palette.success.main} !important`
                    : `${theme.palette.error.main} !important`
                }`,
            }}
          >
            <Typography component="p" py={0.5}>
              {user.ativo ? "Conta ativa" : "Conta desativada"}
            </Typography>
          </Box>
        ) : (
          <React.Fragment></React.Fragment>
        )}

        <Box px={4} py={2}>
          {openError ? (
            <Box mb={4}>
              <Alert message={messageError} open={openError} />
            </Box>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </Box>

        {user ? (
          <React.Fragment>
            <Box mx={4} mb={4}>
              <Box textAlign="end" mb={4}>
                <Button
                  variant="outlined"
                  onClick={() => handleClickOpenUDialog(user.id, user.nome)}
                  type="button"
                  color="secondary"
                  startIcon={<DeleteIcon />}
                >
                  Excluir
                </Button>
              </Box>

              <SubTitlePage>{user.nome}</SubTitlePage>

              <Divider
                sx={{
                  marginBottom: 4,
                }}
              />

              {/* verified */}
              <Box mb={4}>
                <FormGroup>
                  <FormControlLabel
                    onChange={(event) => handleChangeStatus(event, user.id)}
                    control={
                      <Switch defaultChecked={user.ativo ? true : false} />
                    }
                    label="Conta ativa"
                  />
                </FormGroup>
              </Box>

              {/* Dados pessoais */}
              <Box mb={4}>
                <SubTitlePage>Dados pessoais</SubTitlePage>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: "100%" }}>
                    <TableBody>
                      {/* ID */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          ID
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {user.id}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Nome completo */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Nome completo
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {user.nome}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Login */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Login
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {user.login}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Email */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          E-mail
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {user.email ? user.email : "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Permissão */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Permissão
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {user.permissao === "0" ? "Leitura" : ""}
                          {user.permissao === "1" ? "Escrita" : ""}
                          {user.permissao === "2" ? "Execução" : ""}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Dialog open={openDialog} onClose={handleClickCloseUDialog}>
                <DialogTitle>{"Você tem certeza?"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Tem certeza de que deseja excluir "{userName}"? Registros
                    uma vez excluídos não podem ser recuperados.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Box mx={1} mb={1}>
                    <Button
                      onClick={handleClickCloseUDialog}
                      variant="text"
                      sx={{ marginRight: 2 }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={handleChangeDelete}
                      autoFocus
                      color="error"
                      variant="outlined"
                    >
                      Sim, tenho certeza.
                    </Button>
                  </Box>
                </DialogActions>
              </Dialog>
            </Box>
          </React.Fragment>
        ) : (
          <Box my={5} textAlign="center">
            <CircularProgress color="secondary" />
          </Box>
        )}
      </Drawer>
    </React.Fragment>
  );
};
