/* Material */
import { Box, Paper } from "@mui/material";

export const BoxPaper = ({ children }) => {
  return (
    <Box mt={5}>
      <Paper
        elevation={6}
        sx={{
          backgroundColor: (theme) =>
            `${theme.palette.background.document} !important`,
          backgroundImage: "none",
        }}
      >
        <Box px={3} py={4}>
          {children}
        </Box>
      </Paper>
    </Box>
  );
};
