import { api } from "../../../services/api";

import { getToken } from "../../../oauth/fx/token";

export const getUser = async () => {
  try {
    const token = getToken();

    if (token) {
      let response = await api.get("/user-info", {
        headers: {
          authorization: `Authorization ${token}`,
        },
      });

      const data = response.data;

      return data;
    } else {
      return null;
    }
  } catch (error) {
    return error;
  }
};

export const List = async (page, limit, search) => {
  try {
    let response = await api.get("/contratante-paginacao/colecao", {
      headers: {
        startindex: page,
        limit: limit,
        search: search,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const Show = async (id) => {
  try {
    let response = await api.get("/contratante-paginacao", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const CreateEmployer = async (nome, email, uf) => {
  try {
    let response = await api.post("/contratante", {
      nome,
      email,
      uf,
      urlfoto: "user.png",
      aprovado: true,
      ativo: true,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const ShowInstitution = async (id) => {
  try {
    let response = await api.get("/contratante-instituicao", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const ShowInstitutionUnity = async (id) => {
  try {
    let response = await api.get("/instituicao-unidade", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const VerifyAccount = async (checked, id) => {
  try {
    let response = await api.put(`/contratante/aprovado/${id}/`, { checked });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};
