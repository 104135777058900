/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Material */
import {
  Box,
  Paper,
  InputBase,
  Divider,
  Typography,
  CircularProgress,
  TablePagination,
} from "@mui/material";

/* Icons */
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

/* Components */
import { Table } from "../../../../components/app/Table";
import { Alert } from "../../../../components/app/Alert";
import { TitlePage } from "../../../../components/app/Title";

/* Components Page */
import { TableRow } from "../components/TableRow";
import { DrawerGraduation } from "../components/Drawer/Graduation";

/* Functions */
import { List } from "../util";

/* Table */
const tableColumns = [
  { title: "Graduação", align: "left" },
  { title: "Área", align: "center", size: "220px" },
  { title: "Cadastrado em", align: "center", size: "140px" },
];

export const DataTable = () => {
  // states
  const [graduation, setGraduation] = useState(null);
  const [search, setSearch] = useState("");
  const [tempSearch, setTempSearch] = useState("");

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const [qtd, setQtd] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value));

    setPage(1);
  };

  const handleChangeSearch = (event) => {
    setTempSearch(event.target.value);
  };

  const deleteSearch = () => {
    setTempSearch("");
    setSearch("");

    setPage(1);
  };

  const submitSearch = () => {
    setSearch(tempSearch);

    setPage(1);
  };

  const load = useCallback(async () => {
    try {
      setGraduation(null);

      setTimeout(async () => {
        await List(page, limit, search)
          .then((response) => {
            const { result, qtd } = response;

            // Object.keys(response).forEach(async function (item) {});

            let qtdPage = qtd.count || 0;

            setGraduation(result);
            setQtd(qtdPage);
          })
          .catch((error) => {
            setMessageError(error.message);
            setOpenError(true);
          });
      }, 500);
    } catch (error) {
      console.log(error);
      setMessageError(error.message);
      setOpenError(true);
    }
  }, [page, limit, search]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load, page, limit, search]);

  return (
    <React.Fragment>
      <TitlePage>Graduação</TitlePage>

      <Box mb={4}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={qtd}
          rowsPerPage={limit}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <Box
        my={5}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            p: "6px 4px",
            display: "flex",
            alignItems: "center",
            maxWidth: "800px",
            width: "100%",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Pesquisar por área ou graduação."
            inputProps={{ "aria-label": "Pesquisar por área ou graduação." }}
            value={tempSearch}
            onChange={handleChangeSearch}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
                submitSearch();
              }
            }}
          />

          <IconButton
            onClick={submitSearch}
            type="submit"
            sx={{ p: "10px" }}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>

          {tempSearch !== "" ? (
            <React.Fragment>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

              <IconButton
                onClick={deleteSearch}
                color="secondary"
                sx={{ p: "10px" }}
                aria-label="Delete"
              >
                <DeleteIcon />
              </IconButton>
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </Paper>
      </Box>

      <Alert message={messageError} open={openError} />

      {graduation ? (
        <React.Fragment>
          {graduation.length > 0 ? (
            <React.Fragment>
              <Table
                columns={tableColumns}
                component={TableRow}
                rows={graduation}
                drawer={DrawerGraduation}
                refreshList={load}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box textAlign="center" mt={4} mb={5} px={2}>
                <AutoStoriesIcon fontSize="large" color="secondary" />
                <Typography
                  component="div"
                  variant="h6"
                  fontWeight={600}
                  color="secondary"
                  mt={1}
                >
                  Nenhum registro encontrado.
                </Typography>
              </Box>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <Box my={5} textAlign="center">
          <CircularProgress color="secondary" />
        </Box>
      )}

      <Box mt={4}>
        <TablePagination
          sx={{ display: "flex", justifyContent: "center" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={qtd}
          rowsPerPage={limit}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </React.Fragment>
  );
};
