import { api } from "../../../services/api";

export const List = async (
  page,
  limit,
  search,
  idVaga,
  filterArea,
  filterAreaOther,
  filterGraduation,
  filterGraduationOther,
  filterGraduationDegree,
  filterCourse,
  filterCep
) => {
  try {
    let response = await api.get("/vaga/inscrito/candidato-paginacao/colecao", {
      headers: {
        startindex: page,
        limit: limit,
        search: search,
        job: idVaga,
        area: filterArea,
        area_other: filterAreaOther,
        graduation: filterGraduation,
        graduation_other: filterGraduationOther,
        graduation_degree: filterGraduationDegree,
        course: filterCourse,
        cep: filterCep,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const ListArea = async () => {
  try {
    let response = await api.get("/filtro-area/colecao");

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const ListGraduation = async () => {
  try {
    let response = await api.get("/filtro-graduacao/colecao");

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const Show = async (id, idVaga) => {
  try {
    let response = await api.get("/vaga/inscrito/candidato-paginacao", {
      headers: {
        id,
        job: idVaga,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const ShowGraduation = async (id) => {
  try {
    let response = await api.get("candidato-graduacao", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const ShowGraduationDegree = async (id) => {
  try {
    let response = await api.get("candidato-posgraduacao", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const ShowCourse = async (id) => {
  try {
    let response = await api.get("candidato-curso", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const ShowSchedules = async (vaga, candidato) => {
  try {
    let response = await api.get("candidato-agendamento", {
      headers: {
        vaga,
        candidato,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const VerifyAccount = async (checked, id) => {
  try {
    let response = await api.put(`/candidato/aprovado/${id}/`, { checked });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const UpdateSubscriptionDescription = async (text, id) => {
  try {
    let response = await api.put(`/candidato-vaga-descricao/${id}`, {
      desc: text,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const CreateArea = async (
  titulo,
  dt_agendamento,
  descricao,
  idVaga,
  idCandidato
) => {
  try {
    let response = await api.post("/agendamento", {
      titulo,
      dt_agendamento,
      descricao,
      vaga: idVaga,
      candidato: idCandidato,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};
