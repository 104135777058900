/* Router */
import { Routes, Route, Navigate } from "react-router-dom";

/* OAuth pages */
import { Login } from "../../pages/OAuth/Login";
import { Logout } from "../../pages/OAuth/Logout";

/* Private pages */
import { Home } from "../../pages/Dashboard/Home";
import { Area } from "../../pages/Dashboard/Area";
import { UniversityGraduate } from "../../pages/Dashboard/UniversityGraduate";
import { Candidate } from "../../pages/Dashboard/Candidate";
import { Employer } from "../../pages/Dashboard/Employer";
import { JobVacancy } from "../../pages/Dashboard/JobVacancy";
import { JobVacancyCandidate } from "../../pages/Dashboard/JobVacancy/Candidate";
import { JobVacancyScheduling } from "../../pages/Dashboard/JobVacancy/Scheduling";
import { ExternalJobVacancy } from "../../pages/Dashboard/ExternalJobVacancy";
import { ExternalJobVacancyCandidate } from "../../pages/Dashboard/ExternalJobVacancy/Candidate";
import { DirectMail } from "../../pages/Dashboard/DirectMail";
import { Settings } from "../../pages/Dashboard/Settings";

/* Components */
import { OAuthRoutes } from "../../components/routes/OAuthRoutes";
import { PrivateRoutes } from "../../components/routes/PrivateRoutes";

export const AppRoutes = () => {
  return (
    <Routes>
      {/* ================= OAUTH ROUTES ================= */}
      <Route element={<OAuthRoutes />}>
        <Route path="/login" element={<Login />} />
      </Route>

      <Route path="/logout" element={<Logout />} />

      {/* ================= PRIVATE ROUTES ================= */}
      <Route element={<PrivateRoutes />}>
        <Route path="/inicio" element={<Home />} />
        <Route path="/area" element={<Area />} />
        <Route path="/graduacao" element={<UniversityGraduate />} />
        <Route path="/candidato" element={<Candidate />} />
        <Route path="/contratante" element={<Employer />} />
        <Route path="/vaga" element={<JobVacancy />} />
        <Route
          path="/vaga/inscritos/:idVaga"
          element={<JobVacancyCandidate />}
        />
        <Route
          path="/vaga/agendamento/:idVaga/:idCandidato"
          element={<JobVacancyScheduling />}
        />
        <Route path="/trabalho-externo" element={<ExternalJobVacancy />} />
        <Route
          path="/trabalho-externo/inscritos/:idCandidato"
          element={<ExternalJobVacancyCandidate />}
        />
        <Route path="/mala-direta" element={<DirectMail />} />
        <Route path="/conta" element={<Settings />} />
      </Route>

      {/* ================= ERRORS ================= */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
