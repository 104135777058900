/* React */
import React, { createContext, useEffect, useState } from "react";

/* Util */
import {
  getUserLocalStorage,
  setUserLocalStorage,
  removeUserLocalStorage,
  LoginRequest,
  RegisterRequest,
} from "./util";

export const AuthContext = createContext();

export const AppAuthProvider = ({ children }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const user = getUserLocalStorage();
    if (user) setUser(user);
  }, []);

  async function authenticate(login, password, remember) {
    const response = await LoginRequest(login, password, remember);

    // validates if there was an error with the request
    if (response.error_message) {
      return response;
    }

    // save user token
    else {
      const payload = {
        authorization: response,
      };

      setUser(payload);
      setUserLocalStorage(payload);

      return response;
    }
  }

  async function register(fullName, areaCode, phone, login, password) {
    const response = await RegisterRequest(
      fullName,
      areaCode,
      phone,
      login,
      password
    );

    // validates if there was an error with the request
    if (response.error_message) {
      return response;
    }

    // save user token
    else {
      const payload = {
        authorization: response,
      };

      setUser(payload);
      setUserLocalStorage(payload);

      return response;
    }
  }

  function logout() {
    setUser({});
    removeUserLocalStorage();
  }

  return (
    <AuthContext.Provider
      value={{
        ...user,
        authenticate,
        register,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
