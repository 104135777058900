/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Router */
import { useNavigate } from "react-router-dom";

/* Material */
import {
  Box,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  Divider,
  FormGroup,
  FormControlLabel,
  Typography,
  Switch,
  Badge,
  CircularProgress,
} from "@mui/material";

/* Icons */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PersonIcon from "@mui/icons-material/Person";

/* Components */
import { Alert } from "../../../../../../components/app/Alert";
import { SubTitlePage } from "../../../../../../components/app/Title";

/* Functions */
import { Show, StatusJob } from "../../../util";

/* Styles */
import { Drawer, DrawerHeader } from "../../styled/Drawer";
import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../../styled/Table";

export const DrawerJobVacancy = (props) => {
  const navigate = useNavigate();

  // props
  const { open, setOpen, id, refreshList } = props;

  const [jobVacancy, setJobVacancy] = useState(null);

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const closeDrawer = () => {
    setOpen(!open);
    refreshList();
  };

  const handleChangeStatus = async (event, id) => {
    const checked = event.target.checked;

    await StatusJob(checked, id).catch((error) => {
      console.log(error);
      setMessageError(error.message);
      setOpenError(true);
    });
  };

  const goListCandidate = (id) => {
    navigate(`/vaga/inscritos/${id}`);
  };

  const load = useCallback(async () => {
    let unmounted = false;

    try {
      setJobVacancy(null);
      setOpen(open);

      setTimeout(async () => {
        if (id) {
          await Show(id)
            .then((response) => {
              const { result } = response;

              if (!unmounted) {
                setJobVacancy(result);
              }
            })
            .catch((error) => {
              console.log(error);
              setMessageError(error.message);
              setOpenError(true);
            });
        }
      }, 500);
    } catch (error) {
      console.log(error);
      setMessageError(error.message);
      setOpenError(true);
    }

    return () => {
      unmounted = true;
    };
  }, [open, setOpen, id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load, open, setOpen, id]);

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open} onClose={closeDrawer}>
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            px={2}
          >
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={closeDrawer}
            >
              Voltar
            </Button>
          </Box>
        </DrawerHeader>

        <Divider
          sx={{
            borderColor: (theme) => theme.palette.background.default,
          }}
        />

        {jobVacancy ? (
          <Box
            sx={{
              color: "#ffffff",
              textAlign: "center",
              backgroundColor: (theme) =>
                `${
                  jobVacancy.ativo
                    ? `${theme.palette.success.main} !important`
                    : `${theme.palette.error.main} !important`
                }`,
            }}
          >
            <Typography component="p" py={0.5}>
              {jobVacancy.ativo ? "Vaga ativa" : "Vaga desativada"}
            </Typography>
          </Box>
        ) : (
          <React.Fragment></React.Fragment>
        )}

        <Box px={4} py={2}>
          {openError ? (
            <Box mb={4}>
              <Alert message={messageError} open={openError} />
            </Box>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </Box>

        {jobVacancy ? (
          <React.Fragment>
            <Box mx={4} mb={4}>
              <Box textAlign="end" mb={4}>
                <Button
                  variant="outlined"
                  onClick={() => goListCandidate(jobVacancy.id)}
                  type="button"
                  color="secondary"
                  startIcon={
                    <Badge
                      badgeContent={
                        jobVacancy.totInscritos &&
                        !isNaN(parseInt(jobVacancy.totInscritos)) &&
                        parseInt(jobVacancy.totInscritos) > 0
                          ? parseInt(jobVacancy.totInscritos)
                          : 0
                      }
                      max={99999}
                      color="error"
                      showZero
                    >
                      <PersonIcon />
                    </Badge>
                  }
                >
                  Candidatos
                </Button>
              </Box>

              <SubTitlePage>{jobVacancy.titulo}</SubTitlePage>

              <Divider
                sx={{
                  marginBottom: 4,
                }}
              />

              {/* verified */}
              <Box mb={4}>
                <FormGroup>
                  <FormControlLabel
                    onChange={(event) =>
                      handleChangeStatus(event, jobVacancy.id)
                    }
                    control={
                      <Switch
                        defaultChecked={jobVacancy.ativo ? true : false}
                      />
                    }
                    label="Vaga ativa"
                  />
                </FormGroup>
              </Box>

              {/* Dados pessoais */}
              <Box mb={4}>
                <SubTitlePage>Informações</SubTitlePage>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: "100%" }}>
                    <TableBody>
                      {/* ID */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          ID
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {jobVacancy.id}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Graduação */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Graduação
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {jobVacancy.titulo}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Contratante */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Contratante
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {jobVacancy.nome}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Total de inscritos */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Total de inscritos
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {!isNaN(parseInt(jobVacancy.totInscritos))
                            ? jobVacancy.totInscritos
                            : 0}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Total de candidatos */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Total de candidatos
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {!isNaN(parseInt(jobVacancy.totCandidatos))
                            ? jobVacancy.totCandidatos
                            : 0}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/*  Total de desistências */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Total de desistências
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {!isNaN(parseInt(jobVacancy.totDesistentes))
                            ? jobVacancy.totDesistentes
                            : 0}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </React.Fragment>
        ) : (
          <Box my={5} textAlign="center">
            <CircularProgress color="secondary" />
          </Box>
        )}
      </Drawer>
    </React.Fragment>
  );
};
