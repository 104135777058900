/* React */
import React, { useEffect, useState, useCallback } from "react";

/* Router */
import { useNavigate, useLocation } from "react-router-dom";

/* Material */
import {
  List,
  Typography,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

/* Icons */
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import WorkIcon from "@mui/icons-material/Work";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";

/* Options */
import {
  itemsStart,
  itemsEducation,
  itemsMenu,
  itemsSettings,
} from "../../items-menu";

/* Services */
import {
  QtdCandidate,
  QtdEmployer,
  QtdJobVacancy,
  QtdExternalJobVacancy,
} from "../../util";

/* Styles */
import { classes } from "../../styles";

/* Functions */
const activeClass = (current, path) => {
  let result = current.indexOf(path) !== -1;

  return result;
};

export const MenuDrawer = ({ open }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [qtdCandidate, setQtdCandidate] = useState(0);
  const [qtdEmployer, setQtdEmployer] = useState(0);
  const [qtdJobVacancy, setQtdJobVacancy] = useState(0);
  const [qtdExternalJobVacancy, setQtdExternalJobVacancy] = useState(0);

  const load = useCallback(async () => {
    try {
      let unmounted = false;

      (async function () {
        try {
          await QtdCandidate().then((response) => {
            let { qtd } = response;

            if (!unmounted && !isNaN(qtd.count)) {
              setQtdCandidate(parseInt(qtd.count));
            }
          });

          await QtdEmployer().then((response) => {
            let { qtd } = response;

            if (!unmounted && !isNaN(qtd.count)) {
              setQtdEmployer(parseInt(qtd.count));
            }
          });

          await QtdJobVacancy().then((response) => {
            let { qtd } = response;

            if (!unmounted && !isNaN(qtd.count)) {
              setQtdJobVacancy(parseInt(qtd.count));
            }
          });

          await QtdExternalJobVacancy().then((response) => {
            let { qtd } = response;

            if (!unmounted && !isNaN(qtd.count)) {
              setQtdExternalJobVacancy(parseInt(qtd.count));
            }
          });
        } catch (error) {
          return null;
        }
      })();

      return () => {
        unmounted = true;
      };
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load]);

  return (
    <React.Fragment>
      <React.Fragment>
        {open ? (
          <Typography
            component="h1"
            variant="h6"
            classes={{ root: classes.listTitle }}
          >
            {itemsStart.name}
          </Typography>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
          {itemsStart.items.map(function (item, index) {
            return (
              <ListItem
                key={index}
                onClick={() => navigate(item.path)}
                button
                classes={{
                  root: classes.listItem,
                  selected: classes.active,
                }}
                selected={
                  activeClass(location.pathname, item.path) ? true : false
                }
              >
                <ListItemIcon classes={{ root: classes.listIcon }}>
                  <item.icon />
                </ListItemIcon>
                <ListItemText
                  classes={{ secondary: classes.listText }}
                  secondary={item.label}
                />
              </ListItem>
            );
          })}
        </List>
      </React.Fragment>

      <Divider
        sx={{
          borderColor: (theme) => theme.palette.drawer.light,
          marginTop: (theme) => theme.spacing(2),
          marginBottom: (theme) => theme.spacing(2),
        }}
      />

      <React.Fragment>
        {open ? (
          <Typography
            component="h1"
            variant="h6"
            classes={{ root: classes.listTitle }}
          >
            {itemsEducation.name}
          </Typography>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
          {itemsEducation.items.map(function (item, index) {
            return (
              <ListItem
                key={index}
                onClick={() => navigate(item.path)}
                button
                classes={{
                  root: classes.listItem,
                  selected: classes.active,
                }}
                selected={
                  activeClass(location.pathname, item.path) ? true : false
                }
              >
                <ListItemIcon classes={{ root: classes.listIcon }}>
                  <item.icon />
                </ListItemIcon>
                <ListItemText
                  classes={{ secondary: classes.listText }}
                  secondary={item.label}
                />
              </ListItem>
            );
          })}
        </List>
      </React.Fragment>

      <Divider
        sx={{
          borderColor: (theme) => theme.palette.drawer.light,
          marginTop: (theme) => theme.spacing(2),
          marginBottom: (theme) => theme.spacing(2),
        }}
      />

      <React.Fragment>
        {open ? (
          <Typography
            component="h1"
            variant="h6"
            classes={{ root: classes.listTitle }}
          >
            {itemsMenu.name}
          </Typography>
        ) : (
          <React.Fragment></React.Fragment>
        )}

        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <ListItem
            onClick={() => navigate("/candidato")}
            button
            classes={{
              root: classes.listItem,
              selected: classes.active,
            }}
            selected={
              activeClass(location.pathname, "/candidato") ? true : false
            }
          >
            <ListItemIcon classes={{ root: classes.listIcon }}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ secondary: classes.listText }}
              secondary={"Candidatos"}
            />
            <Typography
              component="span"
              className={classes.listTot}
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "35px",
                display: "block",
                overflow: "hidden",
              }}
            >
              {!isNaN(qtdCandidate) && parseInt(qtdCandidate) > 0
                ? qtdCandidate
                : ""}
            </Typography>
          </ListItem>

          <ListItem
            onClick={() => navigate("/contratante")}
            button
            classes={{
              root: classes.listItem,
              selected: classes.active,
            }}
            selected={
              activeClass(location.pathname, "/contratante") ? true : false
            }
          >
            <ListItemIcon classes={{ root: classes.listIcon }}>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ secondary: classes.listText }}
              secondary={"Contratante"}
            />
            <Typography
              component="span"
              className={classes.listTot}
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "25px",
                display: "block",
                overflow: "hidden",
              }}
            >
              {!isNaN(qtdEmployer) && parseInt(qtdEmployer) > 0
                ? qtdEmployer
                : ""}
            </Typography>
          </ListItem>

          <ListItem
            onClick={() => navigate("/vaga")}
            button
            classes={{
              root: classes.listItem,
              selected: classes.active,
            }}
            selected={activeClass(location.pathname, "/vaga") ? true : false}
          >
            <ListItemIcon classes={{ root: classes.listIcon }}>
              <WorkIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ secondary: classes.listText }}
              secondary={"Vagas"}
            />
            <Typography
              component="span"
              className={classes.listTot}
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "25px",
                display: "block",
                overflow: "hidden",
              }}
            >
              {!isNaN(qtdJobVacancy) && parseInt(qtdJobVacancy) > 0
                ? qtdJobVacancy
                : ""}
            </Typography>
          </ListItem>

          <ListItem
            onClick={() => navigate("/trabalho-externo")}
            button
            classes={{
              root: classes.listItem,
              selected: classes.active,
            }}
            selected={
              activeClass(location.pathname, "/trabalho-externo") ? true : false
            }
          >
            <ListItemIcon classes={{ root: classes.listIcon }}>
              <WorkOutlineIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ secondary: classes.listText }}
              secondary={"Vagas Externa"}
            />
            <Typography
              component="span"
              className={classes.listTot}
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "25px",
                display: "block",
                overflow: "hidden",
              }}
            >
              {!isNaN(qtdExternalJobVacancy) &&
              parseInt(qtdExternalJobVacancy) > 0
                ? qtdExternalJobVacancy
                : ""}
            </Typography>
          </ListItem>
        </List>
      </React.Fragment>

      <Divider
        sx={{
          borderColor: (theme) => theme.palette.drawer.light,
          marginTop: (theme) => theme.spacing(2),
          marginBottom: (theme) => theme.spacing(2),
        }}
      />

      <React.Fragment>
        {open ? (
          <Typography
            component="h1"
            variant="h6"
            classes={{ root: classes.listTitle }}
          >
            {itemsSettings.name}
          </Typography>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
          {itemsSettings.items.map(function (item, index) {
            return (
              <ListItem
                key={index}
                onClick={() => navigate(item.path)}
                button
                classes={{
                  root: classes.listItem,
                  selected: classes.active,
                }}
                selected={
                  activeClass(location.pathname, item.path) ? true : false
                }
              >
                <ListItemIcon classes={{ root: classes.listIcon }}>
                  <item.icon />
                </ListItemIcon>
                <ListItemText
                  classes={{ secondary: classes.listText }}
                  secondary={item.label}
                />
              </ListItem>
            );
          })}
        </List>
      </React.Fragment>
    </React.Fragment>
  );
};
