import { api } from "../../../services/api";

import { getToken } from "../../../oauth/fx/token";

export const getUser = async () => {
  try {
    const token = getToken();

    if (token) {
      let response = await api.get("/user-info", {
        headers: {
          authorization: `Authorization ${token}`,
        },
      });

      const data = response.data;

      return data;
    } else {
      return null;
    }
  } catch (error) {
    return error;
  }
};

export const List = async (page, limit, search) => {
  try {
    let response = await api.get("vaga-paginacao/colecao", {
      headers: {
        startindex: page,
        limit: limit,
        search: search,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const Show = async (id) => {
  try {
    let response = await api.get("vaga-paginacao", {
      headers: {
        id: id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export async function StatusJob(checked, id) {
  try {
    let response = await api.put(`/vaga/status/${id}/`, { checked });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export async function ListEmployer() {
  try {
    let response = await api.get("/filtro-contratante/colecao");

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export const CreateJob = async (
  titulo,
  modalidade,
  tituloContratante,
  cep,
  uf,
  descricao,
  linkExterno,
  idContratante,
  confidencial
) => {
  let response = await api.post("/vaga", {
    titulo,
    modalidade,
    tituloContratante,
    cep,
    uf,
    descricao,
    linkExterno,
    idContratante,
    ativo: true,
    confidencial,
  });

  const data = response.data;

  return data;
};
