/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Material */
import {
  Box,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  Divider,
  CircularProgress,
} from "@mui/material";

/* Icons */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

/* Components */
import { Alert } from "../../../../../../components/app/Alert";
import { SubTitlePage } from "../../../../../../components/app/Title";

/* Functions */
import { Show } from "../../../util";

/* Styles */
import { Drawer, DrawerHeader } from "../../styled/Drawer";
import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../../styled/Table";

export const DrawerArea = (props) => {
  // props
  const { open, setOpen, id, refreshList } = props;

  const [area, setArea] = useState(null);

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const closeDrawer = () => {
    setOpen(!open);
    refreshList();
  };

  const load = useCallback(async () => {
    let unmounted = false;

    try {
      setArea(null);
      setOpen(open);

      setTimeout(async () => {
        if (id) {
          await Show(id)
            .then((response) => {
              const { result } = response;

              if (!unmounted) {
                setArea(result);
              }
            })
            .catch((error) => {
              console.log(error);
              setMessageError(error.message);
              setOpenError(true);
            });
        }
      }, 500);
    } catch (error) {
      console.log(error);
      setMessageError(error.message);
      setOpenError(true);
    }

    return () => {
      unmounted = true;
    };
  }, [open, setOpen, id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load, open, setOpen, id]);

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open} onClose={closeDrawer}>
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            px={2}
          >
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={closeDrawer}
            >
              Voltar
            </Button>
          </Box>
        </DrawerHeader>

        <Divider
          sx={{
            borderColor: (theme) => theme.palette.background.default,
          }}
        />

        <Box px={4} py={2}>
          {openError ? (
            <Box mb={4}>
              <Alert message={messageError} open={openError} />
            </Box>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </Box>

        {area ? (
          <React.Fragment>
            <Box mx={4} mb={4}>
              <SubTitlePage>{area.titulo}</SubTitlePage>

              <Divider
                sx={{
                  marginBottom: 4,
                }}
              />

              {/* Informações */}
              <Box mb={4}>
                <SubTitlePage>Informações</SubTitlePage>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: "100%" }}>
                    <TableBody>
                      {/* ID */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          ID
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {area.id}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Título */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Título
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {area.titulo}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </React.Fragment>
        ) : (
          <Box my={5} textAlign="center">
            <CircularProgress color="secondary" />
          </Box>
        )}
      </Drawer>
    </React.Fragment>
  );
};
