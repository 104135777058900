/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Material */
import { Grid, Box, Button, Divider } from "@mui/material";

/* Icons */
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

/* Components */
import FormControl from "../../../../../../components/app/Form/FormControl";
import useForm from "../../../../../../components/app/Form/FormControl/useForm";
import { Controls } from "../../../../../../components/app/Form/Controls";
import { SubTitlePage } from "../../../../../../components/app/Title";
import { Alert } from "../../../../../../components/app/Alert";

/* Functions */
import { CreateUniversityGraduate, ListArea } from "../../../util";

/* Styles */
import { Drawer, DrawerHeader } from "../../styled/Drawer";

const initialValues = {
  area: "",
  titulo: "",
};

export const DrawerAdd = (props) => {
  // props
  const { open, setOpen } = props;

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const [area, setArea] = useState([]);

  const isEmpty = (value) => {
    const result = value ? "" : "Preencha este campo.";
    return result;
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("titulo" in fieldValues) {
      temp.titulo = isEmpty(fieldValues.titulo);
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    // setValues,
    errors,
    setErrors,
    handleInputChange,
    handleInputBlur,
  } = useForm(initialValues, true, validate);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setMessageError("");
    setOpenError(false);

    setTimeout(async () => {
      try {
        if (validate()) {
          await CreateUniversityGraduate(values.area, values.titulo)
            .catch((error) => {
              console.log(error);
              setMessageError("Ocorreu um erro ao salvar.");
              setOpenError(true);
            })
            .finally(() => {
              goList();
            });
        } else {
          setMessageError("Por favor, corrija os campos em vermelho.");
          setOpenError(true);
        }
      } catch (error) {
        // internal error
        setMessageError("Ocorreu um erro inesperado na sessão.");
        setOpenError(true);
      }
    }, 100);
  };

  const closeDrawer = () => {
    setOpen(!open);
  };

  const goList = () => {
    window.location.reload();
  };

  const load = useCallback(async () => {
    let unmounted = false;

    try {
      setTimeout(async () => {
        await ListArea().then((response) => {
          const { result } = response;

          if (!unmounted && result.length > 0) {
            let data = [];

            result.filter(function (item) {
              let obj = {
                title: item.titulo,
                value: item.id,
              };

              return data.push(obj);
            });

            setArea(data);
          }
        });
      }, 500);
    } catch (error) {
      console.log(error);

      if (!unmounted) {
        setMessageError(error.message);
        setOpenError(true);
      }
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load]);

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open} onClose={closeDrawer}>
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            px={2}
          >
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={closeDrawer}
            >
              Voltar
            </Button>
          </Box>
        </DrawerHeader>

        <Divider
          sx={{
            borderColor: (theme) => theme.palette.background.default,
            marginBottom: (theme) => theme.spacing(1.5),
          }}
        />

        <Box px={4} py={2}>
          <Box mb={4}>
            <SubTitlePage>Nova Graduação</SubTitlePage>

            <FormControl onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {area && area.length > 0 ? (
                  <Grid item xs={12}>
                    <Controls.Select
                      label="Área"
                      name="area"
                      nullValue={true}
                      items={area}
                      value={values.area}
                      onChange={handleInputChange}
                    />
                  </Grid>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                <Grid item xs={12}>
                  <Controls.Input
                    label="Título"
                    name="titulo"
                    value={values.titulo}
                    error={errors.titulo}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  {openError ? (
                    <Box mb={2}>
                      <Alert message={messageError} open={openError} />
                    </Box>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}

                  <Controls.Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    width="100%"
                    text="Salvar"
                    endIcon={<ArrowForwardIcon />}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};
