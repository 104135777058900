/* Material */
import { Box } from "@mui/material";

/* Components */
import { Sidebar } from "../../../components/app/Sidebar";
import { BoxPaper } from "../../../components/app/Box";
import { Scrollbar } from "../../../components/app/Scrollbar";

/* Components page */
import { DataTable } from "./DataTable";

export const ExternalJobVacancy = () => {
  return (
    <Sidebar>
      <Box px={4}>
        <BoxPaper>
          <Box minHeight="100vh">
            <Scrollbar>
              <DataTable />
            </Scrollbar>
          </Box>
        </BoxPaper>
      </Box>
    </Sidebar>
  );
};
