import { api } from "../../../services/api";

async function ListJobs() {
  try {
    let response = await api.get("/filtro-vaga/colecao");

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

async function ListExternalJobs() {
  try {
    let response = await api.get("/filtro-vaga-externa/colecao");

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

async function ListJobCandidate(id) {
  try {
    let response = await api.get("/filtro-candidato-vaga/colecao", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

async function SendEmail(to, subject, message) {
  try {
    let response = await api.post("/mala-direta/enviar-email", {
      to,
      subject,
      message,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export { ListJobs, ListExternalJobs, ListJobCandidate, SendEmail };
