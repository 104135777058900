/* Material */
import { styled, Drawer as MuiDrawer } from "@mui/material";
import { grey } from "@mui/material/colors";

const drawerWidth = 240;

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
  [theme.breakpoints.up("sm")]: {
    display: "block",
  },
  "& .MuiPaper-root": {
    height: "100vh",
    "::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
      minWidth: "4px",
      minHeight: "4px",
    },
    "::-webkit-scrollbar-track": {
      background: `${grey[200]} !important`,
    },
    "::-webkit-scrollbar-thumb": {
      border: "none",
      borderRadius: 0,
      background: theme.palette.primary.main,
    },
  },
  "& .MuiDrawer-paper": {
    border: "none",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    boxSizing: "border-box",
    overflowX: "hidden",
    backgroundColor: `${theme.palette.drawer.main} !important`,
    backgroundImage: "none !important",

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    ...(!open && {
      width: "55px",

      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    }),
  },
}));

export const DrawerMobile = styled(MuiDrawer)(({ theme, open }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "block",
  },
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
  "& .MuiPaper-root": {
    height: "100vh",
    "::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
      minWidth: "4px",
      minHeight: "4px",
    },
    "::-webkit-scrollbar-track": {
      background: `${grey[200]} !important`,
    },
    "::-webkit-scrollbar-thumb": {
      border: "none",
      borderRadius: 0,
      background: theme.palette.primary.main,
    },
  },
  "& .MuiDrawer-paper": {
    border: "none",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    boxSizing: "border-box",
    backgroundColor: `${theme.palette.drawer.main} !important`,
    borderRight: `1px solid ${theme.palette.drawer.light} !important`,
    backgroundImage: "none !important",

    transition: theme.transitions.create("width", {
      easing: `${theme.transitions.easing.sharp} !important`,
      duration: `${theme.transitions.duration.enteringScreen} !important`,
    }),
    ...(!open && {
      overflowX: "hidden",
      width: theme.spacing(0),

      transition: theme.transitions.create("width", {
        easing: `${theme.transitions.easing.sharp} !important`,
        duration: `${theme.transitions.duration.leavingScreen} !important`,
      }),
    }),
  },
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
