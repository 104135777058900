/* React */
import { useEffect, useState } from "react";

/* Router */
import { useSearchParams } from "react-router-dom";

/* Material */
import { Box, Tabs, Tab, useTheme } from "@mui/material";

/* Components */
import { Sidebar } from "../../../components/app/Sidebar";

/* Components page */
import { Appearance } from "./menu/Appearance";
import { MyAccount } from "./menu/MyAccount";
import { Logout } from "./menu/Logout";

/* Styles */
import { classes, StylesSettings } from "./styles";

/* Custom Components */
function TabPanel(props) {
  const theme = useTheme();

  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            mt: 3,
            p: 0,
            [theme.breakpoints.up("md")]: {
              mt: 0,
              pl: 3,
            },
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

/*
 * Before changing the id remember to
 * change the redirect url in google cloud
 * platform.
 */
const manuTab = [
  { id: 0, label: "Aparência", element: <Appearance /> },
  { id: 1, label: "Minha conta", element: <MyAccount /> },
  { id: 2, label: "Sair", element: <Logout /> },
];

export const Settings = () => {
  /* Get search queries from url */
  const [searchParams] = useSearchParams();

  /* States */
  const [menuItem, setMenuItem] = useState(0);

  /* Function responsible for switching between tabs */
  const handleChangeMenu = (event, newValue) => {
    setMenuItem(newValue);
  };

  /* Redirects based on page url */
  useEffect(() => {
    const redirect = parseInt(searchParams.get("type"));

    if (redirect && !isNaN(redirect)) {
      handleChangeMenu(() => {}, redirect);
    }
  }, [searchParams]);

  return (
    <Sidebar>
      <Box px={4}>
        <StylesSettings>
          <Box className={classes.root}>
            <Box py={4}>
              <Tabs
                orientation="horizontal"
                value={menuItem}
                onChange={handleChangeMenu}
                aria-label="Settings Menu"
                classes={{
                  root: classes.tab,
                  flexContainer: classes.containerTab,
                  indicator: classes.indicatorTab,
                }}
              >
                {manuTab.map(function (item, index) {
                  return (
                    <Tab
                      key={index}
                      classes={{
                        selected: classes.tabSelected,
                      }}
                      label={item.label}
                      {...a11yProps(item.id)}
                    />
                  );
                })}
              </Tabs>
            </Box>

            <Box width="100%">
              {manuTab.map(function (item, index) {
                return (
                  <TabPanel key={index} value={menuItem} index={item.id}>
                    {item.element}
                  </TabPanel>
                );
              })}
            </Box>
          </Box>
        </StylesSettings>
      </Box>
    </Sidebar>
  );
};
