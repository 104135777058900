import { api } from "../../../services/api";

export async function List(
  page,
  limit,
  search,
  idCandidato,
  filterArea,
  filterAreaOther,
  filterGraduation,
  filterGraduationOther,
  filterGraduationDegree,
  filterCourse,
  filterCep
) {
  try {
    let response = await api.get("/vaga/inscrito/candidato-paginacao/colecao", {
      headers: {
        startindex: page,
        limit: limit,
        search: search,
        candidate: idCandidato,
        area: filterArea,
        area_other: filterAreaOther,
        graduation: filterGraduation,
        graduation_other: filterGraduationOther,
        graduation_degree: filterGraduationDegree,
        course: filterCourse,
        cep: filterCep,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export async function ListArea() {
  try {
    let response = await api.get("/filtro-area/colecao");

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export async function ListGraduation() {
  try {
    let response = await api.get("/filtro-graduacao/colecao");

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export async function Show(id) {
  try {
    let response = await api.get("candidato-paginacao", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export async function ShowGraduation(id) {
  try {
    let response = await api.get("candidato-graduacao", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export async function ShowGraduationDegree(id) {
  try {
    let response = await api.get("candidato-posgraduacao", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export async function ShowCourse(id) {
  try {
    let response = await api.get("candidato-curso", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export async function VerifyAccount(checked, id) {
  try {
    let response = await api.put(`/candidato/aprovado/${id}/`, { checked });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}
