/* Router */
import { useNavigate } from "react-router-dom";

/* Material */
import { Box, Button } from "@mui/material";

/* Components */
import { Sidebar } from "../../../../components/app/Sidebar";
import { BoxPaper } from "../../../../components/app/Box";
import { Scrollbar } from "../../../../components/app/Scrollbar";

/* Components page */
import { DataTable } from "../DataTable/Candidate";

/* Icons */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const ExternalJobVacancyCandidate = () => {
  const navigate = useNavigate();

  const goList = (id) => {
    navigate("/trabalho-externo");
  };

  return (
    <Sidebar>
      <Box px={4}>
        <Box
          mt={4}
          sx={{
            textAlign: {
              xs: "start",
              md: "end",
            },
          }}
        >
          <Button
            color="primary"
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={goList}
          >
            Voltar
          </Button>
        </Box>

        <BoxPaper>
          <Box minHeight="100vh">
            <Scrollbar>
              <DataTable />
            </Scrollbar>
          </Box>
        </BoxPaper>
      </Box>
    </Sidebar>
  );
};
