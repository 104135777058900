/* React */
import React, { useState } from "react";

/* Router */
import { useParams, useNavigate } from "react-router-dom";

/* Material */
import { Grid, Box, Button } from "@mui/material";

/* Components */
import { Sidebar } from "../../../../components/app/Sidebar";
import { BoxPaper } from "../../../../components/app/Box";

/* Icons */
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

/* Components */
import FormControl from "../../../../components/app/Form/FormControl";
import useForm from "../../../../components/app/Form/FormControl/useForm";
import { Controls } from "../../../../components/app/Form/Controls";
import { SubTitlePage } from "../../../../components/app/Title";
import { Alert } from "../../../../components/app/Alert";

/* Functions */
import { CreateArea } from "../util.candidate";

const initialValues = {
  titulo: "",
  dt_agendamento: new Date(),
  descricao: "",
};

export const JobVacancyScheduling = () => {
  const navigate = useNavigate();

  let { idCandidato, idVaga } = useParams();

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const isEmpty = (value) => {
    const result = value ? "" : "Preencha este campo.";
    return result;
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("titulo" in fieldValues) {
      temp.titulo = isEmpty(fieldValues.titulo);
    }

    if ("descricao" in fieldValues) {
      temp.descricao = isEmpty(fieldValues.descricao);
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    // handleCompleteChange,
    handleInputBlur,
  } = useForm(initialValues, true, validate);

  async function handleSubmit(event) {
    event.preventDefault();

    setMessageError("");
    setOpenError(false);

    setTimeout(async () => {
      try {
        if (validate()) {
          await CreateArea(
            values.titulo,
            values.dt_agendamento,
            values.descricao,
            idVaga,
            idCandidato
          )
            .catch((error) => {
              console.log(error);
              setMessageError("Ocorreu um erro ao salvar.");
              setOpenError(true);
            })
            .finally(() => {
              goListCandidate();
            });
        } else {
          setMessageError("Por favor, corrija os campos em vermelho.");
          setOpenError(true);
        }
      } catch (error) {
        // internal error
        setMessageError("Ocorreu um erro inesperado na sessão.");
        setOpenError(true);
      }
    }, 100);
  }

  const goListCandidate = () => {
    navigate(`/vaga/inscritos/${idVaga}`);
  };

  return (
    <Sidebar>
      <Box px={4}>
        <Box
          mt={4}
          sx={{
            textAlign: {
              xs: "start",
              md: "end",
            },
          }}
        >
          <Button
            color="primary"
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={goListCandidate}
          >
            Voltar
          </Button>
        </Box>

        <BoxPaper>
          <Box minHeight="100vh">
            <Box px={4} py={2}>
              <Box mb={4}>
                <SubTitlePage>Novo Agendamento</SubTitlePage>

                <FormControl onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Controls.Input
                        label="Título da tarefa"
                        name="titulo"
                        value={values.titulo}
                        onChange={handleInputChange}
                        error={errors.titulo}
                        onBlur={handleInputBlur}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Controls.MobileDatePicker
                        name="dt_agendamento"
                        label="Data do agendamento"
                        value={values.dt_agendamento}
                        error={errors.dt_agendamento}
                        onChange={handleInputChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Controls.TimePicker
                        name="dt_agendamento"
                        label="Horário do agendamento"
                        value={values.dt_agendamento}
                        error={errors.dt_agendamento}
                        onChange={handleInputChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Controls.TextArea
                        label="Descrição"
                        name="descricao"
                        value={values.descricao}
                        onChange={handleInputChange}
                        error={errors.descricao}
                        onBlur={handleInputBlur}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      {openError ? (
                        <Box mb={2}>
                          <Alert message={messageError} open={openError} />
                        </Box>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}

                      <Controls.Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        width="100%"
                        text="Salvar"
                        endIcon={<ArrowForwardIcon />}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Box>
            </Box>
          </Box>
        </BoxPaper>
      </Box>
    </Sidebar>
  );
};
