/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Router */
import { useParams } from "react-router-dom";

/* Material */
import {
  Box,
  Paper,
  InputBase,
  Divider,
  Typography,
  CircularProgress,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";

/* Icons */
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

/* Components */
import { Table } from "../../../../../components/app/Table";
import { Alert } from "../../../../../components/app/Alert";
import { TitlePage } from "../../../../../components/app/Title";

/* Components Page */
import { TableRow } from "../../components/TableRow/Candidate";
import { DrawerCandidate } from "../../components/Drawer/Candidate";

/* Functions */
import { List, ListArea, ListGraduation } from "../../util.candidate";

/* Table */
const tableColumns = [
  { title: "Nome", align: "left" },
  { title: "Inscrição", align: "center", size: "160px" },
  { title: "Observações", align: "center", size: "250px" },
];

export const DataTable = () => {
  let { idVaga } = useParams();

  // states
  const [candidate, setCandidate] = useState(null);

  const [area, setArea] = useState([]);
  const [graduation, setGraduation] = useState([]);

  const [advancedFilter, setAdvancedFilter] = useState(false);

  const [search, setSearch] = useState("");
  const [tempSearch, setTempSearch] = useState("");
  const [filterArea, setFilterArea] = useState("");
  const [filterAreaOther, setFilterAreaOther] = useState("");
  const [filterGraduation, setFilterGraduation] = useState("");
  const [filterGraduationOther, setFilterGraduationOther] = useState("");
  const [filterGraduationDegree, setFilterGraduationDegree] = useState("");
  const [filterCourse, setFilterCourse] = useState("");
  const [filterCep, setFilterCep] = useState("");

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const [qtd, setQtd] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value));

    setPage(1);
  };

  const handleChangeSearch = (event) => {
    setTempSearch(event.target.value);
  };

  const handleChangeFilterArea = (event) => {
    let id = event.target.value;

    setFilterArea(id);
    setFilterAreaOther("");
  };

  const handleChangeFilterGraduation = (event) => {
    let id = event.target.value;

    setFilterGraduation(id);
    setFilterGraduationOther("");
  };

  const handleChangeFilterAreaOther = (event) => {
    let searchValue = event.target.value;

    setFilterAreaOther(searchValue);
  };

  const handleChangeFilterGraduationOther = (event) => {
    let searchValue = event.target.value;

    setFilterGraduationOther(searchValue);
  };

  const handleChangeFilterGraduationDegree = (event) => {
    let searchValue = event.target.value;

    setFilterGraduationDegree(searchValue);
  };

  const handleChangeFilterCourse = (event) => {
    let searchValue = event.target.value;

    setFilterCourse(searchValue);
  };

  const handleChangeFilterCep = (event) => {
    let searchValue = event.target.value;

    setFilterCep(searchValue);
  };

  const deleteSearch = () => {
    setTempSearch("");
    setSearch("");

    setPage(1);
  };

  const submitSearch = () => {
    setSearch(tempSearch);

    setPage(1);
  };

  const load = useCallback(async () => {
    let unmounted = false;

    try {
      setCandidate(null);

      setTimeout(async () => {
        await List(
          page,
          limit,
          search,
          idVaga,
          filterArea,
          filterAreaOther,
          filterGraduation,
          filterGraduationOther,
          filterGraduationDegree,
          filterCourse,
          filterCep
        )
          .then((response) => {
            const { result, qtd } = response;

            let qtdPage = qtd.count || 0;

            if (!unmounted) {
              setCandidate(result);
              setQtd(qtdPage);
            }
          })
          .catch((error) => {
            console.log(error);

            if (!unmounted) {
              setMessageError(error.message);
              setOpenError(true);
            }
          });

        await ListArea().then((response) => {
          const { result } = response;
          if (!unmounted) {
            setArea(result);
          }
        });

        await ListGraduation().then((response) => {
          const { result } = response;
          if (!unmounted) {
            setGraduation(result);
          }
        });
      }, 500);
    } catch (error) {
      console.log(error);

      if (!unmounted) {
        setMessageError(error.message);
        setOpenError(true);
      }
    }

    return () => {
      unmounted = true;
    };
  }, [
    page,
    limit,
    search,
    idVaga,
    filterArea,
    filterAreaOther,
    filterGraduation,
    filterGraduationOther,
    filterGraduationDegree,
    filterCourse,
    filterCep,
  ]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [
    load,
    page,
    limit,
    search,
    idVaga,
    filterArea,
    filterAreaOther,
    filterGraduation,
    filterGraduationOther,
    filterGraduationDegree,
    filterCourse,
    filterCep,
  ]);

  return (
    <React.Fragment>
      <TitlePage>Candidatos</TitlePage>

      <Box mb={4}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={qtd}
          rowsPerPage={limit}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>

      <Box my={5} display="flex" flexDirection="column" alignItems="center">
        <Box maxWidth="800px" width="100%">
          <Paper
            sx={{
              p: "6px 4px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Pesquisar por nome ou e-mail"
              inputProps={{ "aria-label": "Pesquisar por nome ou e-mail" }}
              value={tempSearch}
              onChange={handleChangeSearch}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  ev.preventDefault();
                  submitSearch();
                }
              }}
            />

            <IconButton
              onClick={submitSearch}
              type="submit"
              sx={{ p: "10px" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>

            {tempSearch !== "" ? (
              <React.Fragment>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                <IconButton
                  onClick={deleteSearch}
                  color="secondary"
                  sx={{ p: "10px" }}
                  aria-label="Delete"
                >
                  <DeleteIcon />
                </IconButton>
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </Paper>

          <Box my={2}>
            <FormGroup>
              <FormControlLabel
                onChange={(event) => setAdvancedFilter(event.target.checked)}
                control={<Switch defaultChecked={advancedFilter} />}
                label="Filtros avançados"
              />
            </FormGroup>
          </Box>

          <Box display={advancedFilter === true ? "block" : "none"}>
            <Divider sx={{ marginTop: 3 }} />

            <Grid container my={3} spacing={2}>
              <Grid xs={12} md={filterArea === "other" ? 6 : 12} item>
                <FormControl fullWidth>
                  <InputLabel>Área</InputLabel>
                  <Select
                    value={filterArea}
                    onChange={handleChangeFilterArea}
                    label="Área"
                  >
                    <MenuItem value="">Selecione...</MenuItem>
                    {area.map(function (item, index) {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {item.titulo}
                        </MenuItem>
                      );
                    })}
                    <MenuItem value="other">Outra</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {filterArea === "other" ? (
                <Grid xs={12} md={6} item>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    label="Outra Área"
                    variant="outlined"
                    value={filterAreaOther}
                    onChange={handleChangeFilterAreaOther}
                  />
                </Grid>
              ) : (
                <React.Fragment></React.Fragment>
              )}

              <Grid xs={12} md={filterGraduation === "other" ? 6 : 12} item>
                <FormControl fullWidth>
                  <InputLabel>Graduação</InputLabel>
                  <Select
                    value={filterGraduation}
                    onChange={handleChangeFilterGraduation}
                    label="Graduação"
                  >
                    <MenuItem value="">Selecione...</MenuItem>
                    {graduation.map(function (item, index) {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {item.titulo}
                        </MenuItem>
                      );
                    })}
                    <MenuItem value="other">Outra</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {filterGraduation === "other" ? (
                <Grid xs={12} md={6} item>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    label="Outra Graduação"
                    variant="outlined"
                    value={filterGraduationOther}
                    onChange={handleChangeFilterGraduationOther}
                  />
                </Grid>
              ) : (
                <React.Fragment></React.Fragment>
              )}

              <Grid xs={12} item>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Título da pós-graduação"
                  variant="outlined"
                  value={filterGraduationDegree}
                  onChange={handleChangeFilterGraduationDegree}
                />
              </Grid>

              <Grid xs={12} item>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Título do curso"
                  variant="outlined"
                  value={filterCourse}
                  onChange={handleChangeFilterCourse}
                />
              </Grid>

              <Grid xs={12} md={12} item>
                <FormControl fullWidth>
                  <InputLabel>Endereço</InputLabel>
                  <Select
                    value={filterCep}
                    onChange={handleChangeFilterCep}
                    label="Endereço"
                  >
                    <MenuItem value="">Selecione...</MenuItem>

                    <MenuItem value="01000-19999">São Paulo (SP)</MenuItem>
                    <MenuItem value="20000-28999">Rio de Janeiro (RJ)</MenuItem>
                    <MenuItem value="29000-29999">Espírito Santo (ES)</MenuItem>
                    <MenuItem value="30000-39999">Minas Gerais (MG)</MenuItem>
                    <MenuItem value="40000-48999">Bahia (BA)</MenuItem>
                    <MenuItem value="49000-49999">Sergipe (SE)</MenuItem>
                    <MenuItem value="50000-56999">Pernambuco (PE)</MenuItem>
                    <MenuItem value="57000-57999">Alagoas (AL)</MenuItem>
                    <MenuItem value="58000-58999">Paraíba (PB)</MenuItem>
                    <MenuItem value="59000-59999">
                      Rio Grande do Norte (RN)
                    </MenuItem>
                    <MenuItem value="60000-63999">Ceará (CE)</MenuItem>
                    <MenuItem value="64000-64999">Piauí (PI)</MenuItem>
                    <MenuItem value="65000-65999">Maranhão (MA)</MenuItem>
                    <MenuItem value="66000-68899">Pará (PA)</MenuItem>
                    <MenuItem value="68900-68999">Amapá (AP)</MenuItem>
                    <MenuItem value="69400-69899">Amazonas (AM)</MenuItem>
                    <MenuItem value="69300-69389">Roraima (RR)</MenuItem>
                    <MenuItem value="69900-69999">Acre (AC)</MenuItem>
                    <MenuItem value="70000-73699">
                      Distrito Federal (DF)
                    </MenuItem>
                    <MenuItem value="74000-76799">Goiás (GO)</MenuItem>
                    <MenuItem value="77000-77995">Tocantins (TO)</MenuItem>
                    <MenuItem value="78000-78899">Mato Grosso (MT)</MenuItem>
                    <MenuItem value="76800-76999">Rondônia (RO)</MenuItem>
                    <MenuItem value="79000-79999">
                      Mato Grosso do Sul (MS)
                    </MenuItem>
                    <MenuItem value="80000-87999">Paraná (PR)</MenuItem>
                    <MenuItem value="88000-89999">Santa Catarina (SC)</MenuItem>
                    <MenuItem value="90000-99999">
                      Rio Grande do Sul (RS)
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Alert message={messageError} open={openError} />

      {candidate ? (
        <React.Fragment>
          {candidate.length > 0 ? (
            <React.Fragment>
              <Table
                columns={tableColumns}
                component={TableRow}
                rows={candidate}
                drawer={DrawerCandidate}
                refreshList={load}
                idPage={idVaga}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box textAlign="center" mt={4} mb={5} px={2}>
                <AutoStoriesIcon fontSize="large" color="secondary" />
                <Typography
                  component="div"
                  variant="h6"
                  fontWeight={600}
                  color="secondary"
                  mt={1}
                >
                  Nenhum registro encontrado.
                </Typography>
              </Box>
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <Box my={5} textAlign="center">
          <CircularProgress color="secondary" />
        </Box>
      )}

      <Box mt={4}>
        <TablePagination
          sx={{ display: "flex", justifyContent: "center" }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={qtd}
          rowsPerPage={limit}
          page={page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </React.Fragment>
  );
};
