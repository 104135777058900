import { api } from "../../../services/api";

export const List = async (page, limit, search) => {
  try {
    let response = await api.get("vaga-externa-paginacao/colecao", {
      headers: {
        startindex: page,
        limit: limit,
        search: search,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const Show = async (id) => {
  try {
    let response = await api.get("vaga-externa-paginacao", {
      headers: {
        id: id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export async function StatusJob(checked, id) {
  try {
    let response = await api.put(`/vaga/status/${id}/`, { checked });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}
