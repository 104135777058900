/* Material */
import { createTheme } from "@mui/material";
import { indigo } from "@mui/material/colors";

import { ptBR } from "@material-ui/core/locale";

export const LightTheme = createTheme(
  {
    palette: {
      mode: "light",
      primary: {
        main: indigo[400],
        dark: indigo[600],
        light: indigo[100],
      },
      secondary: {
        main: "#6c757d",
        dark: "#4b5157",
        light: "#899097",
      },
      success: {
        main: "#28a745",
        light: "#53b86a",
        dark: "#1c7430",
      },
      table: {
        active: indigo[400],
        background: indigo[50],
        selected: indigo[100],
      },
      appBar: {
        main: "#ffffff",
        light: "#d9d9d9",
        dark: "",
      },
      drawer: {
        main: "#18182c",
        light: "#333745",
        dark: "#fb8400",
        contrastText: "#8e8e8e",
      },
      background: {
        default: "#e5e5e5",
        paper: "#ffffff",
        document: "#ffffff",
      },
    },
  },
  ptBR
);
