import React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TableContainer,
  Table,
  TableBody,
  Box,
  Chip,
} from "@mui/material/";

/* Icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/* Styles */
import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../../styled/Table";

/* Functions */
const colorStatus = (status) => {
  let color = "";

  switch (status) {
    case "Cursando":
      color = "info";
      break;

    case "Trancado":
      color = "error";
      break;

    case "Concluído":
      color = "success";
      break;

    default:
      color = "secondary";
      break;
  }

  return color;
};

export const AccordionGraduation = (props) => {
  const { result } = props;

  return (
    <React.Fragment>
      {result.map((item, index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex">
                <Chip
                  label={item.status}
                  size="small"
                  color={colorStatus(item.status)}
                  mx={1}
                />
                <Typography
                  mx={1}
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "280px",
                    display: "block",
                    overflow: "hidden",
                  }}
                >
                  {item.idCursoGraduacao
                    ? item.graduacaoTitulo
                    : item.cursoOutro || "Não informado"}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer mb={4}>
                <Table sx={{ minWidth: "100%" }}>
                  <TableBody>
                    {/* Instituição */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Instituição
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.instituicao ? item.instituicao : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    {/* Área */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Área
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.idAreaGraduacao
                          ? item.areaTitulo
                          : item.areaOutra}
                      </StyledTableCell>
                    </StyledTableRow>

                    {/* Graduação */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Graduação
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.idCursoGraduacao
                          ? item.graduacaoTitulo
                          : item.cursoOutro}
                      </StyledTableCell>
                    </StyledTableRow>

                    {/* Início */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Ano de Início
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.anoInicio ? item.anoInicio : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    {/* Término */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Ano de Término
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.anoTermino ? item.anoTermino : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </React.Fragment>
  );
};
