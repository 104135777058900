/* React */
import React from "react";

/* Material */
import {
  Box,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@mui/material";

export function Select(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    items,
    nullValue,
  } = props;

  return (
    <Box mb={"0.75rem"}>
      <FormControl
        variant="outlined"
        {...(error && { error: true })}
        sx={{ width: "100%" }}
      >
        <InputLabel>{label}</InputLabel>

        <MuiSelect label={label} name={name} value={value} onChange={onChange}>
          {nullValue ? (
            <MenuItem value="">Selecione...</MenuItem>
          ) : (
            <MenuItem hidden sx={{ display: "none" }} />
          )}

          {items.map(function (item, index) {
            return (
              <MenuItem key={index} value={item.value}>
                {item.title}
              </MenuItem>
            );
          })}
        </MuiSelect>

        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </Box>
  );
}
