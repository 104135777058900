import { api } from "../../../services/api";

import { getToken } from "../../../oauth/fx/token";

export const getUser = async () => {
  try {
    const token = getToken();

    if (token) {
      let response = await api.get("/user-info", {
        headers: {
          authorization: `Authorization ${token}`,
        },
      });

      const data = response.data;

      return data;
    } else {
      return null;
    }
  } catch (error) {
    return error;
  }
};

export const UpdateUserInfo = async (nome, email, login, id) => {
  try {
    let response = await api.put(`/user-info/${id}`, {
      nome,
      email,
      login,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const UpdateUserSecure = async (password, id) => {
  try {
    let response = await api.put(`/user-secure/${id}`, {
      senha: password,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};
