/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Material */
import {
  Box,
  Paper,
  Button,
  TableContainer,
  Table,
  Link,
  TableBody,
  Divider,
  FormGroup,
  FormControlLabel,
  Switch,
  CircularProgress,
  Typography,
} from "@mui/material";

/* Icons */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

/* Components */
import { Alert } from "../../../../../../components/app/Alert";
import { SubTitlePage } from "../../../../../../components/app/Title";

/* Functions */
import {
  Show,
  VerifyAccount,
  ShowGraduation,
  ShowGraduationDegree,
  ShowCourse,
} from "../../../util";

/* Components page */
import { AccordionGraduation } from "../../Accordion/Graduation";
import { AccordionGraduateDegree } from "../../Accordion/GraduateDegree";
import { AccordionCourse } from "../../Accordion/Course";

/* Styles */
import { Drawer, DrawerHeader } from "../../styled/Drawer";
import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../../styled/Table";

const formatPhone = (phone) => {
  phone = phone
    ? `https://api.whatsapp.com/send?phone=55${phone
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .replace("-", "")}`
    : "";
  return phone;
};

export const DrawerCandidate = (props) => {
  // props
  const { open, setOpen, id, refreshList } = props;

  // states
  const [candidate, setCandidate] = useState(null);
  const [graduation, setGraduation] = useState(null);
  const [graduateDegree, setGraduateDegree] = useState(null);
  const [course, setCourse] = useState(null);

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const closeDrawer = () => {
    setOpen(!open);
    refreshList();
  };

  const handleChangeVerify = async (event, id) => {
    const checked = event.target.checked;

    await VerifyAccount(checked, id).catch((error) => {
      console.log(error);
      setMessageError(error.message);
      setOpenError(true);
    });
  };

  const load = useCallback(async () => {
    let unmounted = false;

    try {
      setCandidate(null);
      setGraduation(null);
      setGraduateDegree(null);
      setCourse(null);

      setOpen(open);

      setTimeout(async () => {
        if (id) {
          await Show(id)
            .then((response) => {
              const { result } = response;

              if (!unmounted) {
                setCandidate(result);
              }
            })
            .catch((error) => {
              console.log(error);

              if (!unmounted) {
                setMessageError(error.message);
                setOpenError(true);
              }
            });

          await ShowGraduation(id)
            .then((response) => {
              const { result } = response;

              if (!unmounted) {
                setGraduation(result);
              }
            })
            .catch((error) => {
              console.log(error);

              if (!unmounted) {
                setMessageError(error.message);
                setOpenError(true);
              }
            });

          await ShowGraduationDegree(id)
            .then((response) => {
              const { result } = response;

              if (!unmounted) {
                setGraduateDegree(result);
              }
            })
            .catch((error) => {
              console.log(error);

              if (!unmounted) {
                setMessageError(error.message);
                setOpenError(true);
              }
            });

          await ShowCourse(id)
            .then((response) => {
              const { result } = response;

              if (!unmounted) {
                setCourse(result);
              }
            })
            .catch((error) => {
              console.log(error);

              if (!unmounted) {
                setMessageError(error.message);
                setOpenError(true);
              }
            });
        }
      }, 500);
    } catch (error) {
      console.log(error);

      if (!unmounted) {
        setMessageError(error.message);
        setOpenError(true);
      }
    }

    return () => {
      unmounted = true;
    };
  }, [open, setOpen, id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted && open && id) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load, open, setOpen, id]);

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open} onClose={closeDrawer}>
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            px={2}
          >
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={closeDrawer}
            >
              Voltar
            </Button>
          </Box>
        </DrawerHeader>

        <Divider
          sx={{
            borderColor: (theme) => theme.palette.background.default,
          }}
        />

        {candidate ? (
          <Box
            sx={{
              color: "#ffffff",
              textAlign: "center",
              backgroundColor: (theme) =>
                `${
                  candidate.ativo
                    ? `${theme.palette.success.main} !important`
                    : `${theme.palette.error.main} !important`
                }`,
            }}
          >
            <Typography component="p" py={0.5}>
              {candidate.ativo ? "Conta ativa" : "Conta desativada"}
            </Typography>
          </Box>
        ) : (
          <React.Fragment></React.Fragment>
        )}

        <Box px={4} py={2}>
          {openError ? (
            <Box mb={4}>
              <Alert message={messageError} open={openError} />
            </Box>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </Box>

        {candidate ? (
          <React.Fragment>
            <Box mx={4} mb={4}>
              <SubTitlePage>{candidate.nome}</SubTitlePage>

              <Divider
                sx={{
                  marginBottom: 4,
                }}
              />

              {/* verified */}
              <Box mb={4}>
                <FormGroup>
                  <FormControlLabel
                    onChange={(event) =>
                      handleChangeVerify(event, candidate.id)
                    }
                    control={
                      <Switch
                        defaultChecked={candidate.aprovado ? true : false}
                      />
                    }
                    label="Conta verificada"
                  />
                </FormGroup>
              </Box>

              {/* Dados pessoais */}
              <Box mb={4}>
                <SubTitlePage>Dados pessoais</SubTitlePage>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: "100%" }}>
                    <TableBody>
                      {/* ID */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          ID
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {candidate.id}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Nome completo */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Nome completo
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {candidate.nome}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Email */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          E-mail
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {candidate.email}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* dataNascimento */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Data de Nasc.
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {candidate.dataNascimento
                            ? candidate.dataNascimento
                            : "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* Sobre */}
              <Box mb={4}>
                <SubTitlePage>Sobre</SubTitlePage>
                <TableContainer component={Paper} mb={4}>
                  <Table sx={{ minWidth: "100%" }}>
                    <TableBody>
                      {/* descricao */}
                      <StyledTableRow>
                        {/* <StyledTablecleCellTitle> */}

                        <StyledTableCell align="right">
                          {candidate.descricao
                            ? candidate.descricao
                            : "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* Contato */}
              <Box mb={4}>
                <SubTitlePage>Contato</SubTitlePage>
                <TableContainer component={Paper} mb={4}>
                  <Table sx={{ minWidth: "100%" }}>
                    <TableBody>
                      {/* telefone */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Telefone
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {candidate.telefone ? (
                            <Link
                              href={formatPhone(candidate.telefone)}
                              target="_blank"
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                            >
                              <WhatsAppIcon sx={{ height: 15 }} />
                              {candidate.telefone}
                            </Link>
                          ) : (
                            "Não informado"
                          )}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* cep */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          CEP
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {candidate.cep ? candidate.cep : "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* uf */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          UF
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {candidate.uf ? candidate.uf : "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* Social */}
              <Box mb={4}>
                <SubTitlePage>Social</SubTitlePage>
                <TableContainer component={Paper} mb={4}>
                  <Table sx={{ minWidth: "100%" }}>
                    <TableBody>
                      {/* Facebook */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Facebook
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {candidate.facebook ? (
                            <Link href={candidate.facebook} target="_blank">
                              VISUALIZAR
                            </Link>
                          ) : (
                            "Não informado"
                          )}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Instagram */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Instagram
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {candidate.instagram ? (
                            <Link href={candidate.instagram} target="_blank">
                              VISUALIZAR
                            </Link>
                          ) : (
                            "Não informado"
                          )}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* LinkedIn */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          LinkedIn
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {candidate.linkdin ? (
                            <Link href={candidate.linkdin} target="_blank">
                              VISUALIZAR
                            </Link>
                          ) : (
                            "Não informado"
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* Pretensão Salarial */}
              <Box mb={4}>
                <SubTitlePage>Pretensão Salarial</SubTitlePage>
                <TableContainer component={Paper} mb={4}>
                  <Table sx={{ minWidth: "100%" }}>
                    <TableBody>
                      {/* Mínimo por hora */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Mínimo por hora
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {candidate.minimoSalarialHora
                            ? "R$ " + candidate.minimoSalarialHora
                            : "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Mínimo por mês */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Mínimo por mês
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {candidate.minimoSalarialMes
                            ? "R$ " + candidate.minimoSalarialMes
                            : "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Desejável por hora */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Desejável por hora
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {candidate.pretencaoSalarialHora
                            ? "R$ " + candidate.pretencaoSalarialHora
                            : "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Desejável por mês */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Desejável por mês
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {candidate.pretencaoSalarialMes
                            ? "R$ " + candidate.pretencaoSalarialMes
                            : "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {graduation ? (
                <Box mb={5}>
                  <Typography mb={1.5} fontWeight={600}>
                    Graduação
                  </Typography>

                  {graduation.length > 0 ? (
                    <AccordionGraduation result={graduation} />
                  ) : (
                    <Box textAlign="center">
                      <Typography component="div" color="secondary">
                        Nenhum registro encontrado.
                      </Typography>
                    </Box>
                  )}
                </Box>
              ) : (
                <React.Fragment></React.Fragment>
              )}

              {graduateDegree ? (
                <Box mb={5}>
                  <Typography mb={1.5} fontWeight={600}>
                    Pós Graduação
                  </Typography>

                  {graduateDegree.length > 0 ? (
                    <AccordionGraduateDegree result={graduateDegree} />
                  ) : (
                    <Box textAlign="center">
                      <Typography component="div" color="secondary">
                        Nenhum registro encontrado.
                      </Typography>
                    </Box>
                  )}
                </Box>
              ) : (
                <React.Fragment></React.Fragment>
              )}

              {course ? (
                <Box mb={5}>
                  <Typography mb={1.5} fontWeight={600}>
                    Curso
                  </Typography>

                  {course.length > 0 ? (
                    <AccordionCourse result={course} />
                  ) : (
                    <Box textAlign="center">
                      <Typography component="div" color="secondary">
                        Nenhum registro encontrado.
                      </Typography>
                    </Box>
                  )}
                </Box>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box my={5} textAlign="center">
              <CircularProgress color="secondary" />
            </Box>
          </React.Fragment>
        )}
      </Drawer>
    </React.Fragment>
  );
};
