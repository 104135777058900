/* Services */
import { api } from "../../services/api";

export function setUserLocalStorage(user) {
  localStorage.setItem("us-token", JSON.stringify(user));
}

export function removeUserLocalStorage() {
  localStorage.removeItem("us-token");
}

export function getUserLocalStorage() {
  const json = localStorage.getItem("us-token");

  if (!json) {
    return null;
  }

  const user = JSON.parse(json);
  return user || null;
}

export async function LoginRequest(login, password, remember) {
  try {
    const response = await api.get("/oauth", {
      headers: {
        login,
        password,
        remember,
      },
    });

    const data = response.data;
    return data;
  } catch (error) {
    return null;
  }
}

export async function RegisterRequest(
  fullName,
  areaCode,
  phone,
  login,
  password
) {
  try {
    const response = await api.post("/oauth", {
      fullName,
      areaCode,
      phone,
      login,
      password,
    });

    const data = response.data;
    return data;
  } catch (error) {
    return null;
  }
}
