/* React */
import React, { useState, useEffect, useCallback } from "react";

/* Material */
import {
  Box,
  Button,
  Paper,
  TableContainer,
  Table,
  TableBody,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  Link,
  Divider,
  CircularProgress,
} from "@mui/material";

/* Icons */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

/* Components */
import { Alert } from "../../../../../../components/app/Alert";
import { SubTitlePage } from "../../../../../../components/app/Title";

/* Functions */
import {
  Show,
  VerifyAccount,
  ShowInstitution,
  // ShowInstitutionUnity,
} from "../../../util";

/* Components page */
import { AccordionInstitution } from "../../Accordion/Institution";

/* Styles */
import { Drawer, DrawerHeader } from "../../styled/Drawer";
import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../../styled/Table";

const formatPhone = (phone) => {
  phone = phone
    ? `https://api.whatsapp.com/send?phone=55${phone
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .replace("-", "")}`
    : "";
  return phone;
};

export const DrawerEmployer = (props) => {
  // props
  const { open, setOpen, id, refreshList } = props;

  const [employer, setEmployer] = useState(null);
  const [institution, setInstitution] = useState(null);
  // const [unity, setUnity] = useState(null);

  const [messageError, setMessageError] = useState("");
  const [openError, setOpenError] = useState(false);

  const closeDrawer = () => {
    setOpen(!open);
    refreshList();
  };

  const handleChangeVerify = async (event, id) => {
    const checked = event.target.checked;

    await VerifyAccount(checked, id).catch((error) => {
      console.log(error);
      setMessageError(error.message);
      setOpenError(true);
    });
  };

  const load = useCallback(async () => {
    let unmounted = false;

    try {
      setEmployer(null);
      setOpen(open);

      setTimeout(async () => {
        if (id) {
          await Show(id)
            .then((response) => {
              const { result } = response;

              if (!unmounted) {
                setEmployer(result);
              }
            })
            .catch((error) => {
              console.log(error);
              setMessageError(error.message);
              setOpenError(true);
            });

          await ShowInstitution(id)
            .then((response) => {
              const { result } = response;

              if (!unmounted) {
                setInstitution(result);
              }
            })
            .catch((error) => {
              console.log(error);
              setMessageError(error.message);
              setOpenError(true);
            });

          // await ShowInstitutionUnity(id)
          //   .then((response) => {
          //     const { result } = response;

          //     console.log(result);

          //     if (!unmounted) {
          //       setUnity(result);
          //     }
          //   })
          //   .catch((error) => {
          //     console.log(error);
          //     setMessageError(error.message);
          //     setOpenError(true);
          //   });
        }
      }, 500);
    } catch (error) {
      console.log(error);
      setMessageError(error.message);
      setOpenError(true);
    }

    return () => {
      unmounted = true;
    };
  }, [open, setOpen, id]);

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      load();
    }

    return () => {
      unmounted = true;
    };
  }, [load, open, setOpen, id]);

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open} onClose={closeDrawer}>
        <DrawerHeader>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            width="100%"
            px={2}
          >
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={closeDrawer}
            >
              Voltar
            </Button>
          </Box>
        </DrawerHeader>

        <Divider
          sx={{
            borderColor: (theme) => theme.palette.background.default,
          }}
        />

        {employer ? (
          <Box
            sx={{
              color: "#ffffff",
              textAlign: "center",
              backgroundColor: (theme) =>
                `${
                  employer.ativo
                    ? `${theme.palette.success.main} !important`
                    : `${theme.palette.error.main} !important`
                }`,
            }}
          >
            <Typography component="p" py={0.5}>
              {employer.ativo ? "Conta ativa" : "Conta desativada"}
            </Typography>
          </Box>
        ) : (
          <React.Fragment></React.Fragment>
        )}

        <Box px={4} py={2}>
          {openError ? (
            <Box mb={4}>
              <Alert message={messageError} open={openError} />
            </Box>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </Box>

        {employer ? (
          <React.Fragment>
            <Box mx={4} mb={4}>
              <SubTitlePage>{employer.nome}</SubTitlePage>

              <Divider
                sx={{
                  marginBottom: 4,
                }}
              />

              {/* verified */}
              <Box mb={4}>
                <FormGroup>
                  <FormControlLabel
                    onChange={(event) => handleChangeVerify(event, employer.id)}
                    control={
                      <Switch
                        defaultChecked={employer.aprovado ? true : false}
                      />
                    }
                    label="Conta verificada"
                  />
                </FormGroup>
              </Box>

              {/* Informações */}
              <Box mb={4}>
                <SubTitlePage>Informações</SubTitlePage>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: "100%" }}>
                    <TableBody>
                      {/* ID */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          ID
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {employer.id}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Nome */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Nome
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {employer.nome ? employer.nome : "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* E-mail */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          E-mail
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {employer.email ? employer.email : "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Telefone */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Telefone
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {employer.telefone ? (
                            <Link
                              href={formatPhone(employer.telefone)}
                              target="_blank"
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                            >
                              <WhatsAppIcon sx={{ height: 15 }} />
                              {employer.telefone}
                            </Link>
                          ) : (
                            "Não informado"
                          )}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Tipo de instituição */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Tipo de instituição
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {employer.tipo_instituicao
                            ? employer.tipo_instituicao
                            : "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* UF */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          UF
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {employer.uf ? employer.uf : "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Cargo */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Cargo
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {employer.cargo ? employer.cargo : "Não informado"}
                        </StyledTableCell>
                      </StyledTableRow>

                      {/* Deseja receber novidades */}
                      <StyledTableRow>
                        <StyledTableCellTitle align="left">
                          Deseja receber novidades
                        </StyledTableCellTitle>

                        <StyledTableCell align="right">
                          {employer.novidades ? "Sim" : "Por enquanto não."}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {institution ? (
                <Box mb={5}>
                  <Typography mb={1.5} fontWeight={600}>
                    Instituição
                  </Typography>

                  {institution.length > 0 ? (
                    <AccordionInstitution result={institution} />
                  ) : (
                    <Box textAlign="center">
                      <Typography component="div" color="secondary">
                        Nenhum registro encontrado.
                      </Typography>
                    </Box>
                  )}
                </Box>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </Box>
          </React.Fragment>
        ) : (
          <Box my={5} textAlign="center">
            <CircularProgress color="secondary" />
          </Box>
        )}
      </Drawer>
    </React.Fragment>
  );
};
