/* React */
import React, { useState } from "react";

/* Material */
import {
  Box,
  Button,
  Typography,
  Avatar,
  TableRow as MuiTableRow,
} from "@mui/material";

/* Icons */
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

/* Components */
import {
  classesTable,
  StylesMuiTableCell as MuiTableCellBody,
} from "../../../../../components/app/Table";

// functions
const formatDate = (date) => {
  let dateString = new Date(date)
    .toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      // hour: "2-digit",
      // minute: "2-digit",
    })
    .replace(/\//g, "/");

  return dateString;
};

function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
}

function stringAvatar(foto, name) {
  let props = {};

  if (foto) {
    props = {
      src: `${foto}`,
    };
  } else {
    props = {
      children: `${String(name.split(" ")[0][0]).toUpperCase()}`,
      sx: {
        bgcolor: stringToColor(name),
      },
    };
  }

  return props;
}

export const TableRow = (props) => {
  // props
  const { row, openDrawer } = props;

  // functions
  function useHover() {
    const [hovering, setHovering] = useState(false);

    const onHoverProps = {
      onMouseEnter: () => setHovering(true),
      onMouseLeave: () => setHovering(false),
    };

    return [hovering, onHoverProps];
  }

  // states
  const [tdIsHovering, tdHoverProps] = useHover();

  return (
    <MuiTableRow
      {...tdHoverProps}
      onClick={openDrawer}
      sx={[
        {
          "&:hover td div.table-containerCellActive, &:hover td div.table-containerCell":
            {
              backgroundColor: (theme) => theme.palette.table.selected,
            },
        },
      ]}
    >
      {/* Nome */}
      <MuiTableCellBody className={classesTable.root} align="left">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width="100%"
        >
          {tdIsHovering ? (
            <Box
              sx={{
                marginRight: 1.75,
                height: "50px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: (theme) => theme.spacing(0.75),
              }}
            >
              <Button
                color="primary"
                variant="contained"
                type="button"
                endIcon={<ArrowForwardIcon />}
              >
                Editar
              </Button>
            </Box>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          <Box component="div" className={classesTable.containerCellActive}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "280px",
                display: "block",
                overflow: "hidden",
              }}
            >
              {row.nome}
            </Typography>
          </Box>
        </Box>
      </MuiTableCellBody>

      {/* Foto */}
      <MuiTableCellBody className={classesTable.root} align="left">
        <Box
          component="div"
          className={classesTable.containerCell}
          sx={{ justifyContent: "center", width: 80 }}
        >
          <Avatar
            alt={row.nome}
            className={classesTable.avatar}
            {...stringAvatar(row.foto, row.nome)}
          />
        </Box>
      </MuiTableCellBody>

      {/* Status */}
      <MuiTableCellBody className={classesTable.root} align="left">
        <Box
          component="div"
          className={classesTable.containerCell}
          sx={{
            justifyContent: "center",
            width: 120,
            backgroundColor: (theme) =>
              `${
                row.ativo
                  ? `${theme.palette.success.main} !important`
                  : `${theme.palette.error.main} !important`
              }`,
            color: "#ffffff",
          }}
        >
          {row.ativo ? "Ativo" : "Desativado"}
        </Box>
      </MuiTableCellBody>

      {/* Permissão */}
      <MuiTableCellBody className={classesTable.root} align="left">
        <Box
          component="div"
          className={classesTable.containerCell}
          sx={{
            justifyContent: "center",
            width: 145,
          }}
        >
          {row.permissao === "0" ? <Typography>Leitura</Typography> : ""}
          {row.permissao === "1" ? <Typography>Escrita</Typography> : ""}
          {row.permissao === "2" ? <Typography>Execução</Typography> : ""}
        </Box>
      </MuiTableCellBody>

      {/* Inscrição */}
      <MuiTableCellBody className={classesTable.root} align="left">
        <Box
          component="div"
          className={classesTable.containerCell}
          sx={{
            justifyContent: "center",
            width: 105,
          }}
        >
          {formatDate(row.createdAt)}
        </Box>
      </MuiTableCellBody>
    </MuiTableRow>
  );
};
