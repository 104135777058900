import React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TableContainer,
  Table,
  TableBody,
  Box,
} from "@mui/material/";

/* Icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EventIcon from "@mui/icons-material/Event";

/* Styles */
import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../../styled/Table";

/* Functions */
const formatDate = (date) => {
  let dateString = new Date(date)
    .toLocaleString("pt-BR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
    .replace(/\//g, "/");

  return dateString;
};

export const AccordionSchedule = (props) => {
  const { result } = props;

  return (
    <React.Fragment>
      {result.map((item, index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" alignItems="center">
                <EventIcon sx={{ marginRight: 1 }} />
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "280px",
                    display: "block",
                    overflow: "hidden",
                  }}
                >
                  {item.dt_agendamento
                    ? formatDate(item.dt_agendamento)
                    : "Não informado"}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer mb={4}>
                <Table sx={{ minWidth: "100%" }}>
                  <TableBody>
                    {/* ID */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        ID
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.id ? item.id : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    {/* Título */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Título
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.titulo ? item.titulo : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    {/* Descrição */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Descrição
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.descricao ? item.descricao : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    {/* Data */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Data
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.dt_agendamento
                          ? formatDate(item.dt_agendamento)
                          : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </React.Fragment>
  );
};
