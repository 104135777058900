import { api } from "../../../services/api";

import { getToken } from "../../../oauth/fx/token";

export const getUser = async () => {
  try {
    const token = getToken();

    if (token) {
      let response = await api.get("/user-info", {
        headers: {
          authorization: `Authorization ${token}`,
        },
      });

      const data = response.data;

      return data;
    } else {
      return null;
    }
  } catch (error) {
    return error;
  }
};

export const List = async (page, limit, search) => {
  try {
    let response = await api.get("graduacao-paginacao/colecao", {
      headers: {
        startindex: page,
        limit: limit,
        search: search,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export const Show = async (id) => {
  try {
    let response = await api.get("graduacao-paginacao", {
      headers: {
        id,
      },
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};

export async function ListArea() {
  try {
    let response = await api.get("/filtro-area/colecao");

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
}

export const CreateUniversityGraduate = async (area, titulo) => {
  try {
    let response = await api.post("/curso-graduacao", {
      area,
      titulo,
    });

    const data = response.data;

    return data;
  } catch (error) {
    return error;
  }
};
