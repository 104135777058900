import React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TableContainer,
  Table,
  TableBody,
  Box,
} from "@mui/material/";

/* Icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SchoolIcon from "@mui/icons-material/School";

/* Styles */
import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../../styled/Table";

export const AccordionInstitution = (props) => {
  const { result } = props;

  return (
    <React.Fragment>
      {result.map((item, index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" alignItems="center">
                <SchoolIcon sx={{ marginRight: 1 }} />
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "280px",
                    display: "block",
                    overflow: "hidden",
                  }}
                >
                  {item.titulo ? item.titulo : "Não informado"}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer mb={4}>
                <Table sx={{ minWidth: "100%" }}>
                  <TableBody>
                    {/* ID */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        ID
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.id ? item.id : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    {/* Título */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Título
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.titulo ? item.titulo : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    {/* Papel do contratante */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Papel do contratante
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.papelContratanteInstituicao
                          ? item.papelContratanteInstituicao
                          : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    {/* Tipo */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Tipo
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.tipo ? item.tipo : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    {/* UF */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        UF
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.uf ? item.uf : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </React.Fragment>
  );
};
