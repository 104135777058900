import React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TableContainer,
  Table,
  TableBody,
  Box,
} from "@mui/material/";

/* Icons */
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SchoolIcon from "@mui/icons-material/School";

/* Styles */
import {
  StyledTableCell,
  StyledTableCellTitle,
  StyledTableRow,
} from "../../styled/Table";

/* Functions */
const formatDate = (date) => {
  let dateFormatted = date ? date.split("-").reverse().join("/") : null;

  return dateFormatted;
};

export const AccordionCourse = (props) => {
  const { result } = props;

  return (
    <React.Fragment>
      {result.map((item, index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" alignItems="center">
                <SchoolIcon sx={{ marginRight: 1 }} />
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "280px",
                    display: "block",
                    overflow: "hidden",
                  }}
                >
                  {item.titulo ? item.titulo : "Não informado"}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer mb={4}>
                <Table sx={{ minWidth: "100%" }}>
                  <TableBody>
                    {/* Título */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Título
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.titulo ? item.titulo : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    {/* Instituição */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Instituição
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.instituicao ? item.instituicao : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    {/* Carga Horária */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Carga Horária
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.cargaHoraria
                          ? item.cargaHoraria + "Hrs."
                          : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    {/* Início */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Início
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.inicio
                          ? formatDate(item.inicio)
                          : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>

                    {/* Término */}
                    <StyledTableRow>
                      <StyledTableCellTitle align="left">
                        Término
                      </StyledTableCellTitle>

                      <StyledTableCell align="right">
                        {item.termino
                          ? formatDate(item.termino)
                          : "Não informado"}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </React.Fragment>
  );
};
